import { useEffect, useMemo, useRef } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap/all';

//* Helpers
import { config } from '@/helpers';

//* Style
import ReformContentStyle from './style';

//* HOO'C
import { withLanguageContext, withUIContext } from '@/context';

//* Components
import Text from '@/components/Text';
import Image from '@/components/Image';
import Button from '@/components/Button';
import Container from '@/components/Container';
import CustomLink from '@/components/CustomLink';

const ReformContent = ({ data, title, description, translate, winWidth }) => {
	data = data.slice(0, 3);
	//! Ref
	const wrapperReformRef = useRef(null);
	const triggerRef = useRef(null);
	const firstLoad = useRef(true);

	//! Animate
	useEffect(() => {
		if (wrapperReformRef.current && data.length && firstLoad.current) {
			[...wrapperReformRef.current.children].forEach((item, i) => {
				gsap.set(item, { y: `${winWidth < 768 ? ((i % 3) + 1) * 40 : (4 - (i % 3)) * 40}%`, duration: 0 });
			});

			gsap.to(wrapperReformRef.current, { opacity: 1, duration: 0 });

			triggerRef.current = ScrollTrigger.create({
				start: 'top 70%',
				end: 'top top',
				trigger: wrapperReformRef.current,
				// markers: true,
				once: true,
				onEnter: () => {
					firstLoad.current = false;
					gsap.to([...wrapperReformRef.current.children].reverse(), { y: 0, duration: 0.6, stagger: 0.05 });
				},
			});
		}
		return () => {
			if (triggerRef.current) {
				triggerRef.current.kill();
				triggerRef.current = null;
			}
		};
	}, [data, winWidth]);

	const controlData = useMemo(() => {
		return data?.map((item, i) => {
			return (
				<div
					key={item.id}
					className={`item-reform-wrapper item-reform-${i}`}>
					<Image
						src={item?.image?.src}
						alt={item?.image?.alt}
					/>
					<div className='info-wrapper'>
						<CustomLink
							className={'link-item-card'}
							url={`${config.routes.reforms.path}/${item.slug}`}>
							<Text className={'subtitle white-color h6 font-montserrat-arm-medium'}>{item.title}</Text>
							<div className='wrapper-date-status'>
								<div className='date-wrapper'>
									<Text className={'data white-color font-montserrat-arm-medium p3 date-text'}>{translate('date')}:</Text>
									<Text className={'data white-color font-montserrat-arm-medium p3 date-text'}>{item.year}</Text>
								</div>
								<Text className={'font-montserrat-arm-medium p3 status-text light-blue-color'}>{translate(item.status)}</Text>
							</div>
						</CustomLink>
					</div>
				</div>
			);
		});
	}, [data]);

	return (
		<ReformContentStyle>
			<Container
				isSection
				row>
				<div className={'wrapper-container'}>
					<div className='reform-wrapper-button-title'>
						<div className='wrapper-title-description'>
							<Text
								tag={'h2'}
								className={'uppercase h2 font-montserrat-arm-medium blue-1000 reform-title'}>
								{title}
							</Text>
							<Text className={'p1  blue-1000 reform-description'}>{description}</Text>
						</div>
						<div className='wrapper-button'>
							<Button
								url={config.routes.reforms.path}
								text={'seeMore'}
								className={'secondary p2 blue-900'}
							/>
						</div>
					</div>

					<div
						ref={wrapperReformRef}
						className={'wrapper-reform opacity-0'}>
						{controlData}
					</div>
				</div>
			</Container>
		</ReformContentStyle>
	);
};

export default withUIContext(withLanguageContext(ReformContent, ['translate']), ['winWidth']);
